import React, { FC } from 'react';
import { useWindowResize } from '../hooks/useWindowResize';

interface MapLinkProps {
	text: string;
 
  }

export const MapLink: FC<MapLinkProps> = ({text}) => {

	const { isMobile } = useWindowResize();

  // Example coordinates (replace with your own)
  const latitude = -9.7081752;
  const longitude = -35.8303731;

  // Create a URL with the coordinates
  const mapUrl = isMobile ? `geo:${latitude},${longitude}?q=${latitude},${longitude}` : 
  `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`
	

  return (
    <a title='Open navigation APP' href={mapUrl} className='btn btn-primary  rounded-full' target="_blank" rel="noopener noreferrer">
    {text} 🗺️
    </a>
  );
};

 