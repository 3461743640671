import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Page = () => {
  let { param } = useParams();

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <>
      <div className="hero h-screen bg-secondary">
        <div className="hero-content flex flex-col text-center">
          <h1 className="text-4xl font-bold  my-1">
            Bem vindo ao limbo! {param}
          </h1>
          <h4 className="text-3xl font-thin  my-1">
            Caiu em uma cilada! Essa pagina não foi criada ainda!
          </h4>
          <h1 className="text-2xl font-thin  my-1">Ass: ReactJS God</h1>
          <div
            role="button"
            onClick={handleButtonClick}
            className="w-full btn btn-square btn-circle text-primary text-bold"
          >
            Ir para local seguro ( sem olhar para trás jamais)
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
