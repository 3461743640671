// pages/About.js

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
 
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
// import required modules
import { Pagination, Mousewheel, Keyboard, EffectCube } from "swiper/modules";

import { useState } from "react";
import NavBar from "../../containers/NavBar";
import { Section1 } from "./Section1";
import { Section2 } from "./Section2";
import { Section3 } from "./Section3";

const LandingPage = () => {
 
 

  const [swiper, setSwiper] = useState<SwiperCore>();
  const [activeIndex, setActiveIndex] = useState(0);

  const goToSlide = (index: number) => {
 
    swiper?.slideTo(index);
  };

  const nextSlideBtn = () => {
    swiper?.slideNext();
  };

  const previousSlideBtn = () => {
    swiper?.slidePrev();
  };

  return (
    <div className="App bg-base-100 h-dvh	 ">
      <NavBar isLogged={false}  goToSlide={goToSlide}/>
      <Swiper
	  onRealIndexChange={(element: any)=>setActiveIndex(element.activeIndex)}
        onInit={(swiperCore: any) => {
          setSwiper(swiperCore);
        }}
        effect={"cube"}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        mousewheel={true}
        keyboard={{
          enabled: true,
        }}
        direction={"vertical"}
        pagination={{
          clickable: true,
          dynamicBullets: true,
 
        }}
        modules={[Pagination, Mousewheel, Keyboard, EffectCube]}
        className="h-[calc(100dvh)] overflow-hidden  "
      >
        <SwiperSlide
		tabIndex={0}
          className="
			h-[calc(100dvh)] 
			bg-cover bg-center
			flex justify-center 
			items-center"
        >
          <Section1
            goToSlide={goToSlide}
            previousSlideBtn={previousSlideBtn}
            nextSlideBtn={nextSlideBtn}
            activeIndex={activeIndex}
	 
          />
        </SwiperSlide>
		<SwiperSlide
		tabIndex={1}
          className="
			h-[calc(100dvh)] 
			bg-cover bg-center
			flex justify-center 
			items-center"
        >
          <Section2
            goToSlide={goToSlide}
            previousSlideBtn={previousSlideBtn}
            nextSlideBtn={nextSlideBtn}
            activeIndex={activeIndex}
          />
        </SwiperSlide>
		<SwiperSlide
		tabIndex={2}
          className="
			h-[calc(100dvh)] 
			bg-cover bg-center
			flex justify-center 
			items-center"
        >
          <Section3
            goToSlide={goToSlide}
            previousSlideBtn={previousSlideBtn}
            nextSlideBtn={nextSlideBtn}
            activeIndex={activeIndex}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default LandingPage;
