import React from "react";
import { GiftCardProps } from "./shared/interface/gift";

const GiftCard: React.FC<GiftCardProps> = ({ item, onClick }) => (
  <div
    className="card 
      bg-base-100 w-96 
      shadow-lg m-4 
      hover:shadow-2xl
      first-letter:transition duration-300 hover:-translate-y-4"
  >
    <figure>
      <img src={item.imgs[0]} alt={item.title} />
    </figure>
    <div className="card-body bg-gradient-to-b from-primary to-secondary">
      <h2 className="card-title">{item.title} <div className="badge badge-primary">{item.code}</div></h2>
      <div className="card-actions justify-start">
        {item.categories.map((category, index) => (
          <div key={index} className="badge badge-outline">{category}</div>
        ))}
      </div>
      <div className="text-secondary-content">
        <div className="collapse collapse-arrow bg-base-200">
          <input type="checkbox" name={item._id} />
          <div className="collapse-title text-xl font-medium">Descrição</div>
          <div className="collapse-content">
            <p> {item.description}</p>
          </div>
        </div>
      </div>
      <div className="card-actions justify-between">
      <div className="text-4xl font-bold">{item.price}</div>
        <button disabled={!item.isEnabled} className="btn btn-primary" onClick={() => onClick(item)}>
          Presentear 🎁
        </button>
      </div>
    </div>
  </div>
);

export default GiftCard;
