import { useState, useEffect, FC, useRef } from "react";
import { Blurhash } from "react-blurhash";

interface ImageLoaderProps {
  blurStyle: {
	width: number,
	height: number,
	resolutionX?: number,
	resolutionY?: number,
	hash: string,
  };
  className: string;
  alt: string;
  src: string;
  props?: any;
 }
export const ImageLoader: FC<ImageLoaderProps> = ({
  src,
  alt,
  className,
  blurStyle,
   ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const imgRef = useRef<HTMLImageElement | null>(null);

  const defaultResolution = 32;

  const handleImageLoad = () => {
    setIsLoaded((prev) => true);
  };

  useEffect(() => {
    const img = new Image();
    img.onload = () => setIsLoaded(true);
    img.src = src;
  }, [src]);

  return (
    <>
      <div style={{ display: isLoaded ? "none" : "block" }} >
         <Blurhash
		   className={className}
		 	width={blurStyle.width}
		 	height={blurStyle.height}
			hash={blurStyle.hash}
			punch={1}
			resolutionX={blurStyle.resolutionX ? blurStyle.resolutionX : defaultResolution}
			resolutionY={blurStyle.resolutionY ? blurStyle.resolutionY : defaultResolution}
		 />
      </div>
      <div style={{ display: isLoaded ? "block" : "none" }}>
        <img
          ref={imgRef}
           src={src}
          onError={(err) => alert(err)}
          className={className}
          alt={alt}
          {...props}
        />
      </div>
    </>
  );
};
