import { FC } from "react";
import { LandingSectionInterface } from "../../interfaces/LandingSectionInterface";
import isabelaFace from "@assets/bgs/faceIsabelaMobile.png";
import yuriFace from "@assets/bgs/faceYuriMobile.png";
import coupleImg from "@assets/bgs/newErasCoulple.png";
import { Transition } from "@headlessui/react";
import { ImageLoader } from "../../components/ImageLoader";
import { useState } from "react";

export const Section2: FC<LandingSectionInterface> = ({
  nextSlideBtn,
  previousSlideBtn,
  goToSlide,
  activeIndex,
}) => {
  const hearts = Array.from({ length: 20 }, (_, index) => index + 1);

  const [images, setImages] = useState<any>([]);

  return (
    <>
      <Transition.Root
        show={activeIndex === 1}
        className="bg-gradient-to-b from-primary to-secondary 
		h-full  flex items-center justify-center    flex-col lg:flex-row-reverse p-10  
		black-rainboow-animation"
      >
        <div className="flex flex-col items-center text-right md:text-center relative">
          <Transition.Child
            as="div"
            enter="ease-in duration-[1300ms] transition-all"
            enterFrom="opacity-0 origin-bottom-right  -translate-y-52  -skew-y-12 "
            enterTo="opacity-100 translate-y-0"
            className=""
          >
            <ImageLoader
              src={isabelaFace}
              alt="Isabela a melhor esposa do MUNDO! 💍 "
              className="w-32 md:w-32   lg:w-60 shadow-2xl mask mask-squircle  "
              blurStyle={{
                width: 120,
                height: 120,
                hash: "LZLETi4Us,?t0;-.V[E2%%XAkBWA",
              }}
            />
          </Transition.Child>
          <Transition.Child
            as="strong"
            enter="ease-in delay-[200ms] duration-[1000ms] transition-all"
            enterFrom="opacity-0 translate-y-52  skew-y-12 "
            enterTo="opacity-100 translate-y-0"
            className="text-2xl vertical-txt-lr absolute   left-0 md:left-5 top-1/3 rotate-180"
          >
            Isabela Silva
          </Transition.Child>
          <Transition.Child
            as="span"
            enter="ease-in delay-[800ms] duration-[900ms] transition-all"
            enterFrom="opacity-0 translate-x-52    "
            enterTo="opacity-100 translate-y-0"
            className="ml-11"
          >
            <span className="ml-11">
              Malabarista de tarefas, em um romance eterno com o café{" "}
              <b>frio </b>e apaixonada por desafios que desafiam a lógica.
              Navegando pela vida adulta com graça, charme e fé no pai.
            </span>
          </Transition.Child>
        </div>

        <div className="invisible h-0 md:visible md:h-auto">
          <Transition.Child
            as="div"
            enter="ease-in duration-[1000ms] transition-all"
            enterFrom="opacity-0 scale-150"
            enterTo="opacity-100 translate-y-0 "
          >
            <img
              src={coupleImg}
              alt="coupleImg"
              loading="lazy"
              className="w-32 md:w-32 lg:w-full mask mask-heart shadow-2xl"
            />
          </Transition.Child>
        </div>
        <div className="flex flex-col items-center text-left md:text-center relative">
          <Transition.Child
            as="div"
            enter="ease-in duration-[1300ms] transition-all"
            enterFrom="opacity-0 origin-bottom-left  translate-y-52  skew-y-12 "
            enterTo="opacity-100 translate-y-0"
            className=""
          >
            <ImageLoader
              src={yuriFace}
              alt="Yuri"
              className="w-32 md:w-32 lg:w-60 mask mask-squircle shadow-2xl"
              blurStyle={{
                width: 120,
                height: 120,
                hash: "LkLgkbb_x]M|_Ms8n$az?HaJV@t7",
              }}
            />
          </Transition.Child>
          <Transition.Child
            as="strong"
            enter="ease-in delay-[200ms] duration-[1000ms] transition-all"
            enterFrom="opacity-0 translate-y-52  -skew-y-12 "
            enterTo="opacity-100 translate-y-0"
            className="text-2xl vertical-txt-rl absolute   right-0 md:right-5  top-1/3 rotate-0"
          >
            Yuri Farias
          </Transition.Child>
          <Transition.Child
            as="span"
            enter="ease-in delay-[800ms] duration-[900ms] transition-all"
            enterFrom="opacity-0 -translate-x-52    "
            enterTo="opacity-100 translate-y-0"
            className="mr-11"
          >
            Explorador do absurdo, mestre em criar conexões cósmicas entre café
            e procrastinação. Conduzindo a vida adulta com elegância peculiar e
            um toque de sarcasmo.{" "}
          </Transition.Child>
        </div>

        <ul className="circles">
          {hearts.map((item, index) => (
            <li key={index} className="mask mask-heart "></li>
          ))}
        </ul>
      </Transition.Root>
    </>
  );
};
