// pages/About.js

import React from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

const Execute = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let { action } = useParams();

  const param1 = queryParams.get("param1");
  const param2 = queryParams.get("param2");

  return (
    <>
      <div className="App bg-base-100 h-dvh	">
        <h1 className="text-3xl font-bold  my-5">
          <p>ACTIONT TYPE: {action}</p>
          <p>Param1: {param1}</p>
          <p>Param2: {param2}</p>
        </h1>
        <Outlet />
      </div>
    </>
  );
};

export default Execute;
