import { Link } from "react-router-dom";
import Theme from "../components/ThemeController";
import { FC, useEffect, useState } from "react";

interface DrawerProps {
  open: boolean;
  goToSlide?: (index: number) => void;
}

const Drawer: FC<DrawerProps> = ({ open = false, goToSlide }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <div className="drawer z-50">
      <input
        id="my-drawer"
        checked={isOpen}
        onChange={() => {}}
        type="checkbox"
        className="drawer-toggle"
      />

      <div className="drawer-side">
        <label
          htmlFor="my-drawer"
          onClick={() => setOpen(false)}
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
          {/* Sidebar content here */}
          <li>
            {" "}
            <Theme />
          </li>
          <li>
            <Link to="/gifts"> Presentes</Link>
          </li>
          <li>
            <Link to="/" onClick={() => goToSlide && goToSlide(0)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/" onClick={() => goToSlide && goToSlide(1)}>
              Sobre nós
            </Link>
          </li>
          <li>
            <Link to="/" onClick={() => goToSlide && goToSlide(2)}>
              FAQ
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Drawer;
