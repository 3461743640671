import { FC, useState, useEffect } from "react";

import dLanding1Img from "@assets/bgs/desktop/Dlanding1.png";
import dLanding2Img from "@assets/bgs/desktop/Dlanding3.png";
import dLanding3Img from "@assets/bgs/desktop/Dlanding4.png";
import dLanding4Img from "@assets/bgs/desktop/Dlanding9.png";
import dLanding5Img from "@assets/bgs/desktop/Dlanding10.png";
import dLanding6Img from "@assets/bgs/desktop/Dlanding11.png";
import dLanding7Img from "@assets/bgs/desktop/Dlanding13.png";
import dLanding8Img from "@assets/bgs/desktop/Dlanding15.png";
import dLanding9Img from "@assets/bgs/desktop/Dlanding16.png";

import mLanding2 from "@assets/bgs/mobile/Mlanding2.png";
import mLanding5 from "@assets/bgs/mobile/Mlanding5.png";
import mLanding6 from "@assets/bgs/mobile/Mlanding6.png";
import mLanding8 from "@assets/bgs/mobile/Mlanding8.png";
import mLanding12 from "@assets/bgs/mobile/Mlanding12.png";
import mLanding14 from "@assets/bgs/mobile/Mlanding14.png";
import mLanding13 from "@assets/bgs/mobile/Mlanding13.png";
import { useWindowResize } from "../../hooks/useWindowResize";
import { Countdown } from "../../components/Countdown";
import { LandingSectionInterface } from "../../interfaces/LandingSectionInterface";
import Typewriter from "typewriter-effect";

export const Section1: FC<LandingSectionInterface> = ({
  nextSlideBtn,
  previousSlideBtn,
  goToSlide,
  activeIndex,
}) => {
  const { isMobile } = useWindowResize();

  const [imagesDesktop] = useState([
    dLanding1Img,
    dLanding2Img,
    dLanding3Img,
    dLanding4Img,
    dLanding5Img,
    dLanding6Img,
    dLanding7Img,
    dLanding8Img,
    dLanding9Img,
  ]);

  const [imagesMobile] = useState([
    mLanding2,
    mLanding5,
    mLanding6,
    mLanding8,
    mLanding12,
    mLanding14,
	mLanding13,
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);


  const expressionsTxT = [
	"Vamos Casar! ✨",
	"Vamos firmar os laços! 💍",
    "Hora de subir no altar! 👰🏾‍♂️🤵🏿",
    "Bora juntar as escovas de dentes! 🦷❤️🦷",
    "É chegada a hora do \"sim\"! 🎉",
    "Prontos para a vida a dois? 👫",
    "Vamos oficializar a parada! 📜💖",
    "Hora de amarrar o burro! 🤠👫",
    "Que tal começar o matrimônio? 💏",
    "Estamos prontos para o \"eu aceito\"! 🥂",
    "Bora encarar o casório! 💒",
    "Vamos começar a jornada matrimonial! 🚀❤️",
    "Quem diria, chegou o dia! 📝💞",
    "Estamos preparados para a dança dos noivos! 💃🕺",
    "Vamos começar a construir nossa história! 🏡❤️",
    "É hora da cerimônia do \"para sempre\"! ⏳💍",
    "Bora embarcar na vida a dois! 🚗👫",
    "Prontos para selar o compromisso! 🤝💖",
    "Vamos dar o start na vida de casados! 🚀💑",
    "Hora de dizer \"sim\" para a vida a dois! 💞",
    "Que tal iniciar o capítulo do casamento? 📖💑"
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isMobile)
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesMobile.length);
      else
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesDesktop.length);
    }, 8000);

    return () => clearInterval(intervalId);
  }, [imagesDesktop, imagesMobile, isMobile]);

  return (
    <div
      className="relative h-screen bg-cover bg-center flex justify-center items-center carousel 
	  "
      style={{
        backgroundImage: `url(${
          isMobile ? imagesMobile[currentIndex] : imagesDesktop[currentIndex]
        })`,
      }}
    >
      <div className="absolute inset-0 bg-black animated-opacity flex-1  "></div>
      <div
        className="relative z-10 text-center text-white rainbow-road-notice shadow-lg
	  mb-32 md:mb-0
	    "
      >
        <h1 className="text-4xl font-sans font-bold mb-4 ">
          <Typewriter
            options={{
              strings: expressionsTxT,
              autoStart: true,
              loop: true,
            }}
          />{" "}
        </h1>
        <p className="text-xl mb-8 px-5">
          Junte-se a nós nesta celebração especial dia <b>14 de setembro</b>,
          Maceió - AL
        </p>
        <p className="text-md mb-3 px-5">O show começa em:</p>
        <Countdown />
		
      </div>
	  <div className="absolute bottom-24 md:bottom-12">
			<img alt="scroll down" className="h-56"  src="https://media.tenor.com/NKtXBSvp1_wAAAAi/scroll.gif"/>
		</div>
    </div>
  );
};
