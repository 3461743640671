// pages/About.js

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import BottomSheet from "../containers/BottomSheet";
import GiftCard from "../containers/GiftCard";
import { GiftItem } from "src/containers/shared/interface/gift";
import giftItems from "../assets/giftlist/giftlist.json";
import NavBar from "../containers/NavBar";

const GiftStore: React.FC = () => {
  let { param } = useParams();

  const [selectedItem, setSelectedItem] = useState<GiftItem | null>(null);
  const [isOpened, openMenu] = useState(false);

  const handleBuyNowClick = (item: GiftItem) => {
    setSelectedItem(item);
    openMenu(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    openMenu(false);
  };

  return (
    <div className="App bg-base-100 h-dvh	 ">
      <NavBar isLogged={false} goToSlide={() => {}} />
      <div className="container mx-auto py-8 px-4">
        <h1 className="text-3xl my-3 font-bold text-center mb-8">
          Lista de presentes! 🤩
        </h1>
        <div className="flex flex-wrap justify-center">
          {giftItems.map((item, index) => (
            <GiftCard key={index} item={item} onClick={handleBuyNowClick} />
          ))}
        </div>

        {selectedItem && (
          <BottomSheet
            item={selectedItem}
            onClose={handleCloseModal}
            open={isOpened}
          />
        )}
      </div>
    </div>
  );
};

export default GiftStore;
