import React, { useEffect, useRef, useState } from "react";
import { Sheet, SheetRef } from "react-modal-sheet";
import { GiftItem } from "./shared/interface/gift";
import { random } from "lodash";

export interface BottomSheetProps {
  item: GiftItem;
  open: boolean;
  onClose: () => void;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
  item,
  open = false,
  onClose,
}) => {
  const [isOpen, setOpen] = useState(true);
  const ref = useRef<SheetRef>();

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <>
      <Sheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        snapPoints={[800, 600, 400, 0]}
        initialSnap={0}
        tweenConfig={{ ease: "easeOut", duration: 0.5 }}
        onSnap={(snapIndex) => {
          if (snapIndex === 3) onClose();
        }}
      >
        <Sheet.Container className="bg-gradient-to-b from-slate-400 to-slate-600">
          <Sheet.Content style={{ paddingBottom: ref.current?.y }}>
            <Sheet.Header />
            <Sheet.Scroller draggableAt="both">
              <div className="container mx-auto py-8 px-4">
                <h3 className="text-lg font-bold mb-4">{item.title}</h3>
                <p className="py-4">{item.description}</p>
                <div className="py-6">
                  <h1 className="text-3xl font-extrabold mb-4 text-primary">
                     Como Presentear:
                  </h1>
                  <ol className="text-left list-decimal list-inside space-y-4">
                    <li className="text-lg">
                      <span className="font-semibold">Faça um PIX</span> no
                      valor de <span className="font-extrabold text-primary">{item.price}</span> usando a
                      chave <span className="font-extrabold text-primary">02381148051</span> (CPF). 
                    </li>
                    <li className="text-lg">
                      <span className="font-semibold ">
                        Adicione na mensagem do PIX:
                      </span>{" "}
                      <span className="font-extrabold text-primary">#{item.code} </span>  (Isso vai
                      atualizar essa página em até 
                      <span className="font-bold">{" "} {random(180)} min</span> informando que
                      esse item foi presenteado 🎉).
                    </li>
                    <li className="text-lg">
                      Nós vamos{" "}
                      <span className="font-semibold">comprar o presente</span>{" "}
                      e postar no{" "}
                      <span className="font-semibold">Instagram</span> 📸,
                      marcando você quando ele chegar!
                    </li>
                  </ol>
                  <p className="mt-6 text-lg font-bold">
                    Muito obrigado!! 
                  </p>
                </div>

                <div className="card-actions justify-end">
                  <button className="btn" onClick={onClose}>
                    Voltar
                  </button>
                </div>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </>
  );
};

export default BottomSheet;
