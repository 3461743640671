import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "../App";
import FAQ from "../pages/FAQ";
import Gifts from "../pages/Gifts";
import LandingPage from "../pages/landing/Landing";
import Schedule from "../pages/Schedule";
import Execute from "../pages/commands/Execute";
import Invitations from "../pages/commands/Invitation";
import Live from "../pages/commands/Live";
import Error404 from "../layout/Error404";

const router = createBrowserRouter([
  {
    path: "/",
    Component: LandingPage,
    loader: ({ params }) => {
      // da para carregar api aqui ver depois os detalhes de como fazer
      return false;
    },
	// Children precisa do outlet para rodar como no landing page ta sem a barra de navegacao nao pega esses caras daqui
    children: [],
  },
  //Todo: uma area logada com routers aproprieados 
  { path: "schedule", Component: Error404 },
  { path: "faq", Component: Error404 },
  {
	  path: "gifts", Component: Gifts  
  },
  {
    path: "exec/*",
    Component: Execute,
    children: [
		{ index:true,  path: "invitations/:action", Component: Invitations },
		{ index:true,  path: "live/:action", Component: Live }

	],
  },
]);

const RouteRender = () => <RouterProvider router={router} />;

export default RouteRender;
