import { FC } from "react";
import { MapLink } from "../../components/MapLink";
import { LandingSectionInterface } from "src/interfaces/LandingSectionInterface";
import coupleImg from "@assets/bgs/newErasCoulple.png";
import { Transition } from "@headlessui/react";

export const Section3: FC<LandingSectionInterface> = ({
  nextSlideBtn,
  previousSlideBtn,
  goToSlide,
  activeIndex,
}) => {
  return (
    <div className="relative h-screen flex items-center justify-center py-16 px-5">
      <Transition.Root
        show={activeIndex === 2}
		as="div"
        className="h-full min-w-full black-rainboow-animation max-w-md "
      >
        <Transition.Child
          as="div"
          enter="ease-in duration-[800ms] transition-all"
          enterFrom="opacity-0 origin-bottom-right "
          enterTo="opacity-100 translate-y-0"
          className="chat chat-start w-full  m-0 px-4 "
        >
          <div className="chat-image avatar">
            <div className="w-10 rounded-full">
              <img
                alt="Convidado"
                src="https://images.unsplash.com/photo-1634926878768-2a5b3c42f139?q=80&w=1956&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              />
            </div>
          </div>
          <div className="chat-header">
            Convidado
            <time className="text-xs opacity-50 px-2">12:45</time>
          </div>
          <div className="chat-bubble text-sm">
            E ai, quando e onde vai ser o casamento? 🤔
          </div>
          <div className="chat-footer opacity-50 text-slate-100	">Delivered</div>
        </Transition.Child>
        <Transition.Child
          as="div"
          enter="ease-in delay-[2000ms]  duration-[800ms] transition-all"
          enterFrom="opacity-0 origin-bottom-right "
          enterTo="opacity-100 translate-y-0"
          className="chat chat-end  w-full  m-0 px-4"
        >
          <div className="chat-image avatar">
            <div className="w-10 rounded-full">
              <img alt="Isabela & Yuri" src={coupleImg} />
            </div>
          </div>
          <div className="chat-header">
            Isabela & Yuri
            <time className="text-xs opacity-50 px-2">12:46</time>
          </div>
          <div className="chat-bubble text-sm">
            {" "}
            Será no dia 14 de setembro no sitio guaruça, Marechal Deodoro,
            Alagoas as 16h.{" "}
            <p className="mb-4">
              {" "}
              Para maior comodidade, clique aqui para abrir a navegação que o
              levará diretamente ao endereço 😉💖:{" "}
            </p>{" "}
            <MapLink text="Iniciar navegação" />
          </div>
          <div className="chat-footer opacity-50 text-slate-800	">Seen at 12:46</div>
        </Transition.Child>
        <Transition.Child
          as="div"
          enter="ease-in delay-[4000ms] duration-[800ms] transition-all"
          enterFrom="opacity-0 origin-bottom-right "
          enterTo="opacity-100 translate-y-0"
          className="chat chat-start m-0 px-4 w-full"
        >
          <div className="chat-image avatar">
            <div className="w-10 rounded-full">
              <img
                alt="Convidado"
                src="https://images.unsplash.com/photo-1634926878768-2a5b3c42f139?q=80&w=1956&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              />
            </div>
          </div>
          <div className="chat-header">
            Convidado
            <time className="text-xs opacity-50 px-2">12:50</time>
          </div>
          <div className="chat-bubble text-sm"> E se eu tiver novas duvidas? </div>
          <div className="chat-footer opacity-50 text-slate-800	">Delivered</div>
        </Transition.Child>
        <Transition.Child
          as="div"
          enter="ease-in delay-[6000ms]  duration-[800ms] transition-all"
          enterFrom="opacity-0 origin-bottom-right "
          enterTo="opacity-100 translate-y-0"
          className="chat chat-end  m-0  px-4 w-full"
        >
          <div className="chat-image avatar">
            <div className="w-10 rounded-full">
              <img alt="Isabela & Yuri" src={coupleImg} />
            </div>
          </div>
          <div className="chat-header">
            Isabela & Yuri
            <time className="text-xs opacity-50 px-2">13:06</time>
          </div>
          <div className="chat-bubble h-10 flex justify-center flex-row items-center text-sm">
            {" "}
            So chamar no Zap 😎  
            <a className="btn btn-ghost w-fit h-fit" title="Clica e vai para o grupo meu amigo, sem medo!" href="https://chat.whatsapp.com/KGKNwNbZMNEEuTXT455uu6">
              <img
			  width={55}
			  height={55}
                 src="https://eloffice.com.br/wp-content/uploads/2021/07/whats.gif"
                alt="Whatsapp"
              />
            </a>
          </div>
          <div className="chat-footer opacity-50 text-slate-800	">Seen at 12:46</div>
        </Transition.Child>
      </Transition.Root>
    </div>
  );
};
