import React, { FC, useEffect, useState } from "react";
import Drawer from "./Drawer";

 interface NavBarProps {
	isLogged?: boolean;
	goToSlide?: (index: number) => void;
}

const LoggedNavBar = () => {
  const [isOpened, openMenu] = useState(false);

// Todo: Melhorar esse drwaer recebendo o status dele do component
  return (
    <div className="navbar z-10 absolute top-0 bg-base-100">
      <div className="flex-1">
        <a className="btn btn-ghost text-xl" href="/">{process.env.REACT_APP_TITLE}</a>
      </div>
      <div className="flex-none">
        <Drawer open={isOpened} />
        <div
          tabIndex={0}
          role="button"
          onClick={() => openMenu(!isOpened)}
          className="btn btn-ghost btn-circle"
        >
          <div className="indicator">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4 6H20M4 12H14M4 18H9"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </svg>
          </div>
        </div>

        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
            <div className="indicator">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <span className="badge badge-sm indicator-item">8</span>
            </div>
          </div>
          <div
            tabIndex={0}
            className="mt-3 z-[1] card card-compact dropdown-content w-52 bg-base-100 shadow"
          >
            <div className="card-body">
              <span className="font-bold text-lg">8 Items</span>
              <span className="text-info">Subtotal: $999</span>
              <div className="card-actions">
                <button className="btn btn-primary btn-block">View cart</button>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-ghost btn-circle">
          <div className="indicator">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            <span className="badge badge-xs badge-primary indicator-item"></span>
          </div>
        </button>
        <div className="dropdown dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost btn-circle avatar"
          >
            <div className="w-10 rounded-full">
              <img
                alt="Tailwind CSS Navbar component"
                src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg"
              />
            </div>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <a className="justify-between">
                Profile
                <span className="badge">New</span>
              </a>
            </li>
            <li>
              <a>Settings</a>
            </li>
            <li>
              <a>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const FullScreenMenuGuest = () => {

	return (
		<div>
		<input type="checkbox" id="toggle" className="input-toggler"/>   
		<label htmlFor="toggle" className="menu-toggler">
			<span className="menu-toggler-line"></span>
			<span className="menu-toggler-line"></span>
			<span className="menu-toggler-line"></span>
		</label>
		
		
		<aside className="sidebar">
		<ul className="menu">
		  <li><a className="menu-link" href="#">Home</a></li>
		  <li><a className="menu-link" href="#">Blog</a></li>
		  <li><a className="menu-link" href="#">Portfolio</a></li>
		  <li><a className="menu-link" href="#">About</a></li>
		  <li><a className="menu-link" href="#">Contact</a></li>
		</ul>
		</aside>
		</div>
		
	);
 
}
 


const GuestNavBar:  FC<NavBarProps> = ({goToSlide}) => {
	const [isOpened, openMenu] = useState(false);
  
  // Todo: Melhorar esse drwaer recebendo o status dele do component
	return (
	  <div className="navbar z-10 absolute top-0  ">
		<div className="flex-1">
		  <a className="btn btn-ghost text-2xl text-white">{process.env.REACT_APP_TITLE}</a>
		</div>
		<div className="flex-none">
		  <Drawer goToSlide={goToSlide} open={isOpened} />
		  <div
			tabIndex={0}
			role="button"
			onClick={() => openMenu(!isOpened)}
			className="btn btn-ghost btn-circle"
		  >
			<div className="indicator">
			  <svg
				xmlns="http://www.w3.org/2000/svg"
				className="h-7 w-7 text-white"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			  >
				<svg
				  viewBox="0 0 24 24"
				  fill="none"
				  xmlns="http://www.w3.org/2000/svg"
				>
				  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
				  <g id="SVGRepo_tracerCarrier" strokeLinejoin="round"></g>
				  <g id="SVGRepo_iconCarrier">
					{" "}
					<path
					  d="M4 6H20M4 12H14M4 18H9"
					  stroke="currentColor"
					  strokeWidth="2"
					  strokeLinejoin="round"
					></path>{" "}
				  </g>
				</svg>
			  </svg>
			</div>
		  </div>
		</div>
	  </div>
	);
  };

const NavBar: FC<NavBarProps> = ({isLogged =  false, goToSlide}) => {


	return (
		<>
			{isLogged ? <LoggedNavBar /> : <GuestNavBar goToSlide={goToSlide}/>}
		</>
	)

}
	 

export default NavBar;
