import { useEffect, useState } from "react";

export const Countdown = () => {
	
  const targetDate = new Date("September 14, 2024 16:00:00").getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const currentDate = new Date().getTime();
    const timeRemaining = targetDate - currentDate;

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  useEffect(() => {
	const intervalId = setInterval(() => {
		setTimeRemaining(calculateTimeRemaining());
	  }, 1000);
  
	  return () => {
		clearInterval(intervalId);
	  };
  },[]);

  return (
    <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
      <div className="flex flex-col">
        {timeRemaining.days < 100 ? (
          <span className="countdown font-mono text-5xl">
            <span style={{ "--value": timeRemaining.days } as React.CSSProperties}></span>
          </span>
        ) : (
          <span className=" font-mono text-5xl">{timeRemaining.days}</span>
        )}
        dias
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": timeRemaining.hours } as React.CSSProperties}></span>
        </span>
        horas
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": timeRemaining.minutes } as React.CSSProperties}></span>
        </span>
        minutos
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": timeRemaining.seconds } as React.CSSProperties}></span>
        </span>
        segundos
      </div>
    </div>
  );
};
